import { get } from 'lodash-es';
import { mapState } from 'vuex';

import metaTagsMixin from '~modules/core/js/seo/meta-tags';

import { NEWS_MODULE_NAME } from '~routes/news/js/news-store';

export default {
    mixins: [metaTagsMixin],
    computed: {
        ...mapState(NEWS_MODULE_NAME, [
            'newsHomeContent',
        ]),
        pageTitle() {
            return this.$t('meta__newsCenter--title');
        },
        ogTitle() {
            return this.$t('meta__newsCenter--ogTitle');
        },
        metaDescription() {
            return this.$t('meta__newsCenter--description');
        },
        ogImage() {
            return get(this.newsHomeContent, 'heroArticle.shareImage.smallImage.url');
        },
    },
    getPageTitle() {
        return this.pageTitle;
    },
    getMetaTags() {
        return [{
            name: 'description',
            content: this.metaDescription,
        }, {
            property: 'og:description',
            content: this.metaDescription,
        }, {
            property: 'og:title',
            content: this.ogTitle,
        }, {
            property: 'og:image',
            content: this.ogImage,
        }];
    },
};
