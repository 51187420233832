<template>
    <div
        class="c-page-header o-flex-vertical-center o-flex-horizontal-center"
        :class="{ 'c-page-header--no-image': isEmpty(backgroundImage) }"
        :style="!isEmpty(backgroundImage) && pageHeaderStyle"
    >
        <div class="c-page-header__text-container">
            <p
                v-if="pageSubTitle"
                class="o-text--label u-spacer--1"
                :class="{
                    'u-text--gray': isEmpty(backgroundImage),
                }"
            >
                {{ pageSubTitle }}
            </p>
            <h3
                v-if="pageTitle"
                class="c-page-header__title"
            >
                {{ pageTitle }}
            </h3>
        </div>
    </div>
</template>

<script>
import { get, isEmpty } from 'lodash-es';
import { mapState } from 'vuex';

import { SITE_SETTINGS_MODULE_NAME } from '~coreModules/site-settings/js/site-settings-store';

export default {
    name: 'PageHeader',
    props: {
        pageTitle: {
            type: String,
            default: '',
        },
        pageSubTitle: {
            type: String,
            default: '',
        },
        headerType: {
            type: String,
            default: '',
        },
        backgroundImages: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        ...mapState(SITE_SETTINGS_MODULE_NAME, {
            contentfulAppConfig: state => get(state, 'contentfulAppConfig', {}),
        }),
        backgroundImage() {
            if (this.headerType) {
                return get(this.contentfulAppConfig, `${this.headerType}.backgroundImage`, {});
            }
            return this.backgroundImages;
        },
        smallImage() {
            return get(this.backgroundImage, 'smallImage.url', '');
        },
        largeImage() {
            return get(this.backgroundImage, 'largeImage.url', '');
        },
        smallBackgroundImageUrl() {
            if (!this.smallImage) return false;
            return this.getImageUrl(this.smallImage);
        },
        mediumBackgroundImageUrl() {
            if (!this.largeImage) return false;
            return this.getImageUrl(this.largeImage);
        },
        pageHeaderStyle() {
            return {
                '--small-background-image': this.smallBackgroundImageUrl,
                '--medium-background-image': this.mediumBackgroundImageUrl,
            };
        },
    },
    methods: {
        isEmpty,
        getImageUrl(baseUrl) {
            const quality = this.backgroundImage.imageQuality || 90;
            return `url(${baseUrl}?q=${quality})`;
        },
    },
};

</script>

<style lang="scss">
    .c-page-header {
        background-color: $placeholder-background;
        background-size: cover;
        color: $nu-white;
        text-align: center;
        background-image: var(--small-background-image);
        height: 84px;
        padding: $nu-spacer-2;

        @include breakpoint(medium) {
            background-image: var(--medium-background-image);
            height: 124px;
            padding: $nu-spacer-4 $nu-spacer-2;
        }

        &--no-image {
            background-color: transparent;
            color: $nu-black;
            background-image: none;
        }

        &__title {
            @include breakpoint(small) {
                @include heading-1;
            }
        }
    }
</style>
